import React from "react"

const Attribution = (props) => {
    return (
        <div className="attribution">
            <a rel="license" href="https://creativecommons.org/licenses/by-sa/3.0/deed.en_US">
                <img alt="Creative Commons License" src="https://i.creativecommons.org/l/by-sa/3.0/88x31.png" />
        	</a>
        </div>
    )
}

export default Attribution
