import React from "react"
import menuStyle from "../styles/menu.module.scss"
import { Link } from "gatsby"
import Logo from "../images/logo-light.svg"
import Man from "../images/man-light.svg"

const Menu = (props) => {
    return (
        <div className={menuStyle.root} style={props.style}>
            <div className={menuStyle.logoContainer}>
                <div>
                    <img src={Logo} alt="quipped" className={menuStyle.logo} />
                    <img
                        src={Man}
                        alt="man"
                        className={menuStyle.man}
                    />
                </div>
            </div>

            <div className={menuStyle.navContainer}>
            </div>

            <nav className={menuStyle.nav}>
                <ul>
                    <li>
                        <Link to="/colophon" onClick={() => props.toggleNav(false)}>奥付</Link>
                    </li>
                    <li>
                        <Link to="/" onClick={() => props.toggleNav(false)}>トップ</Link>
                    </li>
                    <li>
                        <Link to="/archives" onClick={() => props.toggleNav(false)}>過去ログ</Link>
                    </li>
                    <li>
                        <Link to="/archives/this-is-water" onClick={() => props.toggleNav(false)}>これは水です。</Link>
                    </li>
                    <li>
                        <Link to="/archives/2020-books" onClick={() => props.toggleNav(false)}>２０２０年に読んだ本</Link>
                    </li>
                    <li>
                        <Link to="/archives/2019-books" onClick={() => props.toggleNav(false)}>２０１９年に読んだ本</Link>
                    </li>
                    <li>
                        <Link to="/archives/2018-books" onClick={() => props.toggleNav(false)}>２０１８年に読んだ本</Link>
                    </li>
                    <li>
                        <Link to="/archives/2017-books" onClick={() => props.toggleNav(false)}>２０１７年に読んだ本</Link>
                    </li>
                    <li>
                        <Link to="/archives/2016-books" onClick={() => props.toggleNav(false)}>２０１６年に読んだ本</Link>
                    </li>
                    <li>
                        <Link to="/archives/34249" onClick={() => props.toggleNav(false)}>２０１５年に読んだ本</Link>
                    </li>
                    <li>
                        <Link to="/archives/32945" onClick={() => props.toggleNav(false)}>２０１４年に読んだ本</Link>
                    </li>
                    <li>
                        <Link to="/archives/22777" onClick={() => props.toggleNav(false)}>２０１３年に読んだ本</Link>
                    </li>
                    <li>
                        <Link to="/archives/15041" onClick={() => props.toggleNav(false)}>２０１２年に読んだ本</Link>
                    </li>
                </ul>
            </nav>

            <div className={menuStyle.sns}>
                <a href="https://twitter.com/tamuramble" target="_blank"  rel="noopener noreferrer" className={menuStyle.twitter} onClick={() => props.toggleNav(false)}>
                </a>
            </div>

            <div className={menuStyle.navToggle}>
                <button onClick={() => props.toggleNav(false)}></button>
            </div>
        </div>
    )
}

export default Menu
