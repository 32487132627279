import React from "react"
import Sidebar from './sidebar'
import Attribution from './attribution'
import Menu from './menu'

import "../styles/index.scss"

class Layout extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          showDrawing: false,
          showMenu: false,
      }
    }

    componentDidMount = () => {
        if( window !== "undefined") {
            window.addEventListener('scroll', this.handleScroll)
        }
    }

    componentWillUnmount = () => {
        if( window !== "undefined") {
            window.removeEventListener('scroll', this.handleScroll)
        }
    }

    openNav = (value) => {
        this.setState({ showMenu: true})
    }

    closeNav = (value) => {
        this.setState({ showMenu: false})
    }

    handleScroll = (e) => {
        if (!this.state.showDrawing) {
            this.setState({ showDrawing: true })
        }

        if(window && window.pageYOffset< 50) {
            this.setState({ showDrawing: false })
        }
    }

    render() {
        const { showMenu } = this.state;

        return (
            <div className="root">
                <Menu
                    toggleNav={this.closeNav}
                    style={{
                        opacity: showMenu ? 1: 0,
                        transform: showMenu ? 'translateY(0)': 'translateY(-100%)',
                    }}
                />
                <div
                    className="main"
                    style={{
                        display: showMenu ? 'none': 'block'
                    }}
                    >
                    <Sidebar
                        showDrawing={this.state.showDrawing}
                        toggleNav={this.openNav}
                    />
                    <div className="container" onScroll={this.handleScroll}>
                        <div className="content">
                            {this.props.children}
                        </div>
                    </div>
                    <Attribution />
                </div>
            </div>
        )
    }
}

export default Layout
