import React from "react"

import { Link } from "gatsby"

import sidebarStyle from "../styles/sidebar.module.scss"
import Logo from "../images/logo.svg"
import Man from "../images/man.svg"

class Sidebar extends React.Component {
    constructor(props) {
      super(props);
      this.state = { showDrawing: false }
    }

    render() {
        const { showDrawing } = this.props;

        return (
            <div className="sidebar">
                <div className={sidebarStyle.root}>
                    <div className={sidebarStyle.logoContainer}>
                        <Link to="/">
                            <img src={Logo} alt="quipped" className={sidebarStyle.logo} />
                            <img
                                src={Man}
                                alt="man"
                                className={sidebarStyle.man}
                                style={{
                                    transform: showDrawing ? 'translate(1.5rem, -2rem)' : 'translate(1.5rem, -15rem)',
                                    opacity: showDrawing ? 1 : 0,

                                }}/>
                        </Link>
                    </div>
                    <nav>
                        <ul className={sidebarStyle.nav}>
                            <li className="heading-size-6">
                                <Link to="/colophon" >奥付</Link>
                            </li>
                            <li className="heading-size-6">
                                <Link to="/" >トップ</Link>
                            </li>
                            <li className="heading-size-6">
                                <Link to="/archives" >過去ログ</Link>
                            </li>
                            <li className="heading-size-6">
                                <Link to="/archives/this-is-water" >これは水です。</Link>
                            </li>
                            <li className="heading-size-6">
                                <Link to="/archives/2022-books" >２０２２年に読んだ本</Link>
                            </li>
                            <li className="heading-size-6">
                                <Link to="/archives/2021-books" >２０２１年に読んだ本</Link>
                            </li>
                            <li className="heading-size-6">
                                <Link to="/archives/2020-books" >２０２０年に読んだ本</Link>
                            </li>
                            <li className="heading-size-6">
                                <Link to="/archives/2019-books" >２０１９年に読んだ本</Link>
                            </li>
                            <li className="heading-size-6">
                                <Link to="/archives/2018-books" >２０１８年に読んだ本</Link>
                            </li>
                            <li className="heading-size-6">
                                <Link to="/archives/2017-books" >２０１７年に読んだ本</Link>
                            </li>
                            <li className="heading-size-6">
                                <Link to="/archives/2016-books" >２０１６年に読んだ本</Link>
                            </li>
                            <li className="heading-size-6">
                                <Link to="/archives/34249" >２０１５年に読んだ本</Link>
                            </li>
                            <li className="heading-size-6">
                                <Link to="/archives/32945" >２０１４年に読んだ本</Link>
                            </li>
                            <li className="heading-size-6">
                                <Link to="/archives/22777" >２０１３年に読んだ本</Link>
                            </li>
                            <li className="heading-size-6">
                                <Link to="/archives/15041" >２０１２年に読んだ本</Link>
                            </li>
                            <li className={sidebarStyle.navToggle}>
                                <button onClick={this.props.toggleNav}></button>
                            </li>
                        </ul>
                    </nav>

                    <div className={sidebarStyle.sns}>
                        <a href="https://twitter.com/tamuramble" target="_blank"  rel="noopener noreferrer" className={sidebarStyle.twitter}>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Sidebar
